<template>
  <div id="account-setting-payout" class="container">
    <div class="page-header mb-1">
      <strong>เปิด/ปิด/ตั้งค่า อัตราจ่าย</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-primary">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="payrate-options p-1 mt-2">
        <button
          v-for="rate in payrates"
          class="btn mx-1"
          :key="rate._id"
          :class="[{'btn-primary':tabActivePayrate===rate._id}, {'btn-light text-primary':tabActivePayrate!==rate._id}]"
          @click="tabActiveRateId=rate._id"
          v-if="rate.status === 'Active'"
        >{{ rate.rateTitle }}</button>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0 table-primary">
          <thead>
            <tr>
              <th class="text-center" rowspan="3">ลำดับ</th>
              <th class="text-center" rowspan="2">เลือก</th>
              <th class="text-center">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th class="text-center">ระดับ</th>
              <th class="text-center">เปิด/ปิด</th>
              <th :colspan="marketOpenBets.length" class="text-center">
                <b-button-group style="width: 300px;">
                  <b-button
                    :variant="showData==='pay'?'info':'outline-info'"
                    @click="showData='pay'"
                  >อัตราจ่าย</b-button>
                  <b-button
                    :variant="showData==='discount'?'info':'outline-info'"
                    @click="showData='discount'"
                  >คอมมิชชั่น</b-button>
                </b-button-group>
              </th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th colspan="3" rowspan="2" class="p-1 font-weight-normal">
                <multiselect
                  v-model="selectedGroups"
                  tag-placeholder="ค้นหาหวย"
                  placeholder="เลือกหวย"

                  group-values="groups"
                  group-label="title"
                  :group-select="true"
                  label="groupTitle"
                  track-by="_id"

                  selectLabel="เลือก"
                  :options="groupOptions"
                  :multiple="true"
                  :taggable="true"
                  :hideSelected="true"
                  :disabled="!bulkAccountIds.length"
                ></multiselect>
              </th>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center">{{ bet.text }}</th>
            </tr>
            <tr>
              <th class="text-center p-0">
                <b-form-checkbox :disabled="!permSetting" v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th v-for="bet in marketOpenBets" :key="bet.code" class="text-center p-1">
                <b-form-input
                  size="sm"
                  :number="true"
                  v-model="inputBulk[bet.code]"
                  :disabled="!bulkAccountIds.length"
                >
                </b-form-input>
              </th>
              <th class="text-center p-0">
                <b-button variant="success" size="sm" :disabled="!bulkAccountIds.length" @click="saveBulk">บันทึก</b-button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in items" :key="account._id">
              <td width="5%" class="text-center">{{ index+1 }}</td>
              <td width="5%" class="text-center">
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="account._id"
                  :disabled="!isAvailable(account) || !permSetting"
                />
              </td>
              <td class="text-center p-0">
                <div class="username">
                  <a v-if="account.role==='Agent' && isAvailable(account)" href="#" @click="toggleChildAccount(account._id)">{{ account.user.username }}</a>
                  <span v-else>{{ account.user.username }}</span>
                </div>
                <small v-if="showName" class="text-secondary">({{ account.user.firstName }})</small>
              </td>
              <td width="10%" class="text-center"><AccountRole :role="account.role" /></td>
              <td width="10%" class="text-center">
                <b-form-checkbox
                  :disabled="!permSetting"
                  v-model="account.groups[tabActiveGroupId].payRate[tabActiveRateId].isAvailable"
                  name="check-button"
                  switch
                  @change="toggleChange(account)"
                  :class="[isAvailable(account) ? 'text-success' : 'text-danger']"
                >
                  {{ isAvailable(account) ? 'เปิด' : 'ปิด' }}
                </b-form-checkbox>
              </td>
              <td width="8%" v-for="bet in marketOpenBets"
                :key="bet.code"
                class="text-right"
                :class="[
                  {'alert-dark': !isAvailable(account)},
                  {'bg-danger text-light': account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code][showData] > refSetting[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code][showData]},
                  {'bg-danger text-light': account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code][showData] > refSetting[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code][showData]}
                ]">
                <span
                  v-if="isAvailable(account)"
                >{{ account.groups[tabActiveGroupId].payRate[tabActiveRateId].rates[bet.code][showData] | numberFormat }}
                </span>
                <span v-else>-</span>
              </td>
              <td width="6%" class="text-center" :class="[{'alert-dark': !isAvailable(account)}]">
                <button :disabled="!isAvailable(account) || !permSetting" class="btn btn-sm btn-outline-secondary" @click="editPayrate(account)"><i class="fas fa-cog"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" :colspan="marketOpenBets.length + 6">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditPayoutModal :data="editData" :refData="refData" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

import { marketOpenBets } from "@/configs/market.config"

import EditPayoutModal from './components/EditPayoutModal'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

const inputBulk = {
  threeNumberTop: '',
  threeNumberTode: '',
  twoNumberTop: '',
  twoNumberBottom: '',
  runTop: '',
  runBottom: ''
}

export default {
  name: 'SettingPayout',
  components: {
    EditPayoutModal,
    Multiselect
  },
  data() {
    return {
      tabActiveGroupId: null,
      tabActiveRateId: null,
      accounts: [],
      isShowModal: false,
      editData: null,
      Breadcrumbs: [],
      refSetting: {},
      accountId: this.$store.state.account._id,
      showData: 'pay',
      showName: false,
      checkAll: false,
      inputBulk: JSON.parse(JSON.stringify(inputBulk)),
      bulkAccountIds: [],
      selectedGroups: [],
      parentSettings: {}
    }
  },
  computed: {
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        const groupRates = this.groupRates(group._id)
        return group.status === 'Active'
          && group.groupType === 'single'
          && this.refSetting[group._id]?.isAvailable
          && groupRates.length
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle
        }
      })
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    payrates() {
      return this.groupRates(this.tabActiveGroup)
    },
    tabActivePayrate() {
      return this.tabActiveRateId
    },
    items() {
      return this.accounts.filter((acc)=>{
        return acc.groups[this.tabActiveGroupId]?.isAvailable
      })
    },
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    marketOpenBets() {
      return marketOpenBets
    },
    refData() {
      return this.refSetting[this.tabActiveGroupId]?.payRate[this.tabActiveRateId]
    },
    checkChangeTab() {
      return `${this.tabActiveGroupId}${this.tabActiveRateId}${this.showData}`
    },
    groupOptions() {
      return this.groups.reduce((options, group)=>{
        options[0].groups.push(group)
        return options
      }, [{
        title: 'เลือกทั้งหมด',
        groups: []
      }])
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MemberPayout === 'w'
    }
  },
  watch: {
    checkChangeTab() {
      this.resetBulk()
    },
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          if(item.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId]?.isAvailable) {
            this.bulkAccountIds.push(item._id)
          }
        }
      }
    },
    tabActiveGroup(n, o) {
      if(n) {

        /**
         * ตั้งค่าเลือกกลุ่ม
         */
        const group = this.groups.find((g)=>{
          return g._id === n
        })
        if(group) {
          this.selectedGroups = [{...group}]
        }

        /**
         * ตั้งค่าเลือกอัตราจ่าย
         */
        if(this.payrates.length > 0) {
          const currentRate = this.payrates.find((rate)=>{
            return rate._id === this.tabActivePayrate
          })

          if(!currentRate || !this.refSetting[this.tabActiveGroup]?.payRate[this.tabActiveRateId]?.isAvailable) {
            this.tabActiveRateId = this.payrates[0]._id
          }
        }
      }
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingPayout(this.accountId)
      .then((response) => {
        if(response.success) {
          this.accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
          this.refSetting = response.data.refSetting
          this.parentSettings = response.data.parentSettings

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }

          if(!this.tabActiveRateId) {
            this.tabActiveRateId = this.payrates[0]?._id
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.resetBulk()
      this.accountId = accountId
      this.loadAccounts()
    },
    toggleChange(account) {
      const updateData = {
        accountId: account._id,
        groupId: this.tabActiveGroupId,
        rateId: this.tabActiveRateId,
        rateTitle: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rateTitle,
        isAvailable: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId]?.isAvailable,
        rateData: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rates
      }
      console.log('updateData', updateData)
      AccountSettingService.updateAccountSettingPayout(updateData)
      .then((response) => {
        if(response.success) {
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        console.log(e.message)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.loadAccounts()
      })
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    editPayrate(account) {
      this.editData = {
        account,
        payRate: {
          accountId: account._id,
          groupId: this.tabActiveGroupId,
          rateId: this.tabActiveRateId,
          rateTitle: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rateTitle,
          isAvailable: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId]?.isAvailable,
          rateData: account.groups[this.tabActiveGroupId].payRate[this.tabActiveRateId].rates
        }
      }
      this.isShowModal = true
    },
    isAvailable(account) {
      return account?.groups[this.tabActiveGroupId]?.payRate[this.tabActiveRateId]?.isAvailable
    },
    saveBulk() {

      if(!this.bulkAccountIds.length) {
        return Swal.fire({
          text: 'กรุณาเลือกสมาชิก',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      if(!this.selectedGroups.length) {
        return Swal.fire({
          text: 'กรุณาเลือกกลุ่มหวย',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      let countInput = 0
      const updateBet = marketOpenBets.reduce((rate, bet)=>{
        if(this.inputBulk[bet.code] !== '') {
          rate[bet.code] = {}
          rate[bet.code][this.showData] = this.inputBulk[bet.code]
          countInput++
        }
        return rate
      }, {})

      if(countInput === 0) {
        return Swal.fire({
          text: 'กรุณากรอกข้อมูลอย่างน้อย 1 ช่อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      const updateRate = {}
      updateRate[this.tabActiveRateId] = updateBet

      const updateGroups = this.selectedGroups.reduce((groups, group)=>{
        groups[group._id] = {
          payRate: updateRate
        }
        return groups
      }, {})

      const groupIds = this.selectedGroups.map((group)=>{
        return group._id
      })

      const rate = this.$store.state.marketPayrates.find((rate)=>{
        return rate._id === this.tabActiveRateId
      })

      const updateData = {
        parentAccountId: this.accountId || this.$store.state.account._id,
        accountIds: this.bulkAccountIds,
        groupIds: groupIds,
        rateId: this.tabActiveRateId,
        rateTitle: rate.rateTitle,
        isAvailable: true,
        rates: updateBet
      }

      console.log(rate, updateData)
      AccountSettingService.updateAccountSettingPayouts(updateData)
      .then((response) => {
        if(response.success) {
          this.resetBulk()
          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'บันทึกเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e) => {
        console.log(e.message)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ!',
          text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(() => {
        this.loadAccounts()
      })
    },
    resetBulk() {
      this.checkAll = false
      this.inputBulk = JSON.parse(JSON.stringify(inputBulk))
      this.bulkAccountIds = []
    },
    groupRates(groupId) {
      return this.$store.state.marketPayrates.filter((rate)=>{
        return rate.status === 'Active'
          && this.refSetting?.[groupId]?.payRate?.[rate._id]?.isAvailable
          && this.parentSettings?.[groupId]?.rates?.[rate._id]?.isAvailable
      })
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }

    if(this.payrates.length === 0) {
      this.$store.dispatch('reloadMarketPayrates')
    }
  },
  mounted() {
    this.loadAccounts()
  },
  filters: {
    numberFormat(num) {
      if(!num)
        return '0.00'

      return parseFloat(num).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;

        div.username {
          font-size: 110%;
        }
        small {
          font-size: 75%;
        }
      }
    }
  }
}
</style>
